<template>
  <div class="legales">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'Legales'
}
</script>

<style lang="less">
.legales {
  background: white;
}
</style>
