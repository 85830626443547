<template>
  <div class="legales-list">
    <h2>
      Legales Pages
      <router-link to="/dashboard/legales/create">
        <a-icon type="file-add" />
      </router-link>
    </h2>
    <div class="responsive-table">
      <table class="table table-striped">
        <thead class="thead-dark">
          <tr>
            <th width="50px" align="center">Order</th>
            <th scope="col">Title</th>
            <th scope="col">Sub-Title</th>
            <th scope="col">Page Type</th>
            <th scope="col">Status</th>
            <th scope="col">Created at</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <draggable
          class="list-group"
          tag="tbody"
          v-model="list"
          :move="handleMove"
          @start="isDragging = true"
          @end="isDragging = false"
        >
          <tr v-for="(item, index) in list" :key="item.order">
            <td>{{ item.order }}</td>
            <td>
              <strong>{{ item.title }}</strong>
            </td>
            <td>{{ item.subtitle || '' }}</td>
            <td>{{ item.hasBody | pageType }}</td>
            <td>{{ item.status }}</td>
            <td>{{ item.created_at | formatDate }}</td>
            <td>
              <a-button-group>
                <a-button
                  type="info"
                  shape="circle"
                  icon="edit"
                  @click="() => $router.push($router.currentRoute.path + '/' + item.id + '/edit')"
                />
                <a-button
                  type="danger"
                  shape="circle"
                  icon="delete"
                  @click="deletePage(item.id, index)"
                />
              </a-button-group>
            </td>
          </tr>
        </draggable>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import draggable from "vuedraggable";
import moment from "moment";

export default {
  name: "LegalesList",
  components: {
    draggable,
  },
  data() {
    return {
      list: null,
    };
  },
  computed: {
    ...mapState(["legales"]),
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  mounted() {
    this.list = this.legales.legales;
  },
  methods: {
    handleMove(e) {
      // legales index 1 change to index 0 (target)
      // legales index 0 change to index 1 (initial)
      const { index, futureIndex } = e.draggedContext;
      this.list[index].order = futureIndex;
      this.list[futureIndex].order = index;

      this.$store.dispatch("swapLegales", {
        a: this.list[index],
        b: this.list[futureIndex],
      });
      return;
    },
    deletePage(page, index) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.value) {
            this.$store
              .dispatch("deleteLegales", { id: page })
              .then((response) => {
                this.list.splice(index, 1);
                this.$swal.fire("Deleted!", response, "success");
              })
              .catch((error) => console.log(error));
          }
        });
    },
  },
  filters: {
    formatDate: (value) => {
      if (!value) return "";
      let date = value.length && value.toDate();

      return moment(date).format("LL");
    },
    pageType: (value) => (value ? "Page" : "Static"),
  },
};
</script>

<style lang="scss">
.responsive-table {
  overflow-x: scroll;
}
</style>